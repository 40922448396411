import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton, Typography, Box, Grid, Button, styled } from '@mui/material';
import { keyframes } from '@mui/system';
import CloseIcon from '@mui/icons-material/CloseRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleNotificationsRoundedIcon from '@mui/icons-material/CircleNotificationsRounded';
import FoxiCharacter from '../../assets/images/home-elements/check-in/foxi-dailycheck.png';
import { userApi } from '../../services/api';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '90%',
  height: '85vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  borderTop: '5px solid orange',
  borderTopLeftRadius: theme.spacing(7),
  borderTopRightRadius: theme.spacing(7), 
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  color: 'white',
  zIndex: 999,
  fontFamily: 'sora, sans-serif',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
}));

const DayButton = styled(Button)(({ theme, status }) => ({
  backgroundColor: status === 'locked' ? '#3b1255' : '#7030a0',
  color: 'white',
  borderRadius: 10,
  padding: '8px 4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  opacity: status === 'locked' ? 0.7 : 1,
  '&:hover': {
    backgroundColor: status === 'locked' ? '#3b1255' : '#7030a0',
  },
  width: '100%',
  height: '100%',
}));

const RewardButton = styled(Button)({
  backgroundColor: '#ffa726',
  color: '#313131',
  borderRadius: 15,
  padding: '2px 6px',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#fb8c00',
  },
  fontFamily: 'sora, sans-serif',
  width: '90%',
  margin: '2px 0',
});

// Flash Animation
const flashAnimation = keyframes`
  0% {
    opacity: 0.85;
    filter: drop-shadow(0 0 5px red);
  }
  50% {
    opacity: 1; 
    filter: drop-shadow(0 0 9px red) drop-shadow(0 0 3px red);
  }
  100% {
    opacity: 0.85; 
    filter: drop-shadow(0 0 5px red);
  }
`;

const coinIconSrc = 'https://img.icons8.com/fluency/50/cheap-2.png';
const diamondIconSrc = 'https://img.icons8.com/color/48/diamond.png';
const clockIconSrc = 'https://img.icons8.com/3d-fluency/188/alarm-clock--v2.png';
const checkInIconSrc = 'https://img.icons8.com/3d-fluency/94/ok.png';
const lockedIconSrc = 'https://img.icons8.com/3d-fluency/94/lock-2.png';

const CheckInPopup = ({ open, onClose, userId, showSnackbar }) => {
  const [checkInStatus, setCheckInStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open && userId) {
      fetchCheckInStatus();
    }
  }, [open, userId]);

  const fetchCheckInStatus = async () => {
    setLoading(true);
    try {
      const response = await userApi.getDailyCheckInStatus(userId);
      setCheckInStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch check-in status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckIn = async (rewardType) => {
    try {
      await userApi.performDailyCheckIn(userId, rewardType);
      showSnackbar('Checked-In successfully!', 'success');
      fetchCheckInStatus();
    } catch (error) {
      console.error('Failed to perform check-in:', error);
      showSnackbar('Failed to check-in. Please try again.', 'error');
    }
  };

  if (loading || !checkInStatus) {
    return <div>Loading...</div>;
  }

  return (
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(100%)' }}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          color: 'white',
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <img
          src={FoxiCharacter}
          alt="Daily bonus character"
          style={{ width: 160, height: 160 }}
        />
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', fontFamily: 'sora, sans-serif' }}>
          Check-in<br />Streak: {checkInStatus.streak}
        </Typography>
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ mr: 1, fontFamily: 'sora, sans-serif' }}>Daily Bonus</Typography>
        <RefreshIcon onClick={fetchCheckInStatus} sx={{ cursor: 'pointer' }} />
      </Box>
      
      <Grid container spacing={2} justifyContent="center">
        {checkInStatus.checkInStatus.map((day) => (
          <Grid item xs={4} key={day.day} sx={{ mb: 2, width: '30vw' }}>
            <DayButton fullWidth status={day.status}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                {day.status === 'completed' && (
                  <Box component='img' src={checkInIconSrc} sx={{ position: 'absolute', top: -12, right: -8, width: '30px', height: '30px' }} />
                )}
                {day.status === 'locked' && (
                  <Box component='img' src={lockedIconSrc} sx={{ position: 'absolute', top: -12, right: -8, width: '30px', height: '30px' }} />
                )}
                {day.status === 'current' && !checkInStatus.canCheckIn && (
                  <Box component='img' src={clockIconSrc} sx={{ position: 'absolute', top: -12, right: -8, width: '30px', height: '30px' }} />
                )}
                {day.status === 'current' && checkInStatus.canCheckIn && (
                  <CircleNotificationsRoundedIcon htmlColor="red" sx={{ position: 'absolute', top: -12, right: -8, fontSize: '25px', animation: `${flashAnimation} 1.5s infinite` }} />
                )}
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontFamily: 'sora, sans-serif' }}>
                  DAY {day.day}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 0.5 }}>
                <Typography variant="body2" fontSize="12px" fontWeight="bold" fontFamily="sora, sans-serif" sx={{color: 'rgb(255, 255, 255, 0.5)' }}>
                  Free
                </Typography>
                <RewardButton 
                  onClick={() => handleCheckIn('free')}
                  disabled={day.status !== 'current' || !checkInStatus.canCheckIn}
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', textAlign: 'center', lineHeight: '1', backgroundColor: 'linen' }}
                >
                   +{checkInStatus.rewards[day.day].free.amount}
                   <img src={checkInStatus.rewards[day.day].free.type === 'points' ? coinIconSrc : diamondIconSrc} alt="Reward Icon" style={{ width: '25px', height: '25px', rotate: '20deg', verticalAlign: 'middle' }} />
                </RewardButton>
                <Typography variant="body2" fontSize="11px" fontWeight="bold" fontFamily="sora, sans-serif" sx={{color: 'rgb(255, 255, 255, 0.5)' }}>
                 Watch Ad
                </Typography>
                <RewardButton 
                  onClick={() => handleCheckIn('ad')}
                  disabled={day.status !== 'current' || !checkInStatus.canCheckIn}
                  sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', textAlign: 'center', lineHeight: '1' }}
                >
                 +{checkInStatus.rewards[day.day].ad.amount} 
                  <img src={diamondIconSrc} alt="Diamond Icon" style={{ width: '25px', height: '25px', rotate: '20deg', verticalAlign: 'middle' }} />
                </RewardButton>
              </Box>
            </DayButton>
          </Grid>
        ))}
      </Grid>
    </PopupContainer>
  );
};

export default CheckInPopup;